import { TicketModel, TicketDTO } from "@djordjeandjelkovic/medgress_common_react_modules";
import { TicketStatusEnum } from "$enums/TicketStatusEnum";

export const mapToTicketDTO = (_ticket: TicketModel): TicketDTO => {
    const ticketInfo = _ticket.ticket;
    let ticket = new TicketDTO(ticketInfo.id, ticketInfo.title, ticketInfo.description);
    ticket.soldTickets = _ticket.soldTickets;
    ticket.soldTicketsPercent = _ticket.soldTicketsPercent;
    ticket.limit = _ticket.ticket.limit;

    if (_ticket.ticket.limit === 0) {
        //TODO Change limit for this check. Limit is maximum, not number of left tickets!
        ticket.status = TicketStatusEnum.SOLD_OUT;
        return ticket;
    }

    if (_ticket.soldTickets === _ticket.ticket.limit) {
        ticket.status = TicketStatusEnum.SOLD_OUT;
        return ticket;
    }

    if (_ticket.pricesList.length === 0) {
        ticket.price = null;
        ticket.status = TicketStatusEnum.AVAILABLE;
        return ticket;
    }

    const priceDetails = getValidTicketPrice(_ticket);
    if (priceDetails === undefined) {
        ticket.status = TicketStatusEnum.NO_AVAILABLE;
        return ticket;
    }

    ticket.status = TicketStatusEnum.AVAILABLE;
    ticket.price = priceDetails;
    ticket.currency = ticketInfo.currency;
    ticket.attendeeType = ticketInfo.attendeeType;
    ticket.displayLimit = ticketInfo.displayLimit;

    return ticket;
};

const getValidTicketPrice = (ticket: TicketModel) => {
    const currentDate = new Date();
    return ticket.pricesList.find((price) => {
        return price.startDate <= currentDate && price.endDate >= currentDate;
    });
};
