import {
    CartTimeoutAction,
    DELETE_TIMEOUT,
    INIT,
    SET_CART_ID_INIT,
    SET_CART_TIMEOUT,
} from "$redux/actions/CartTimeout/cartTimeoutActionsTypes";
import _forEach from "lodash/forEach";
import _keys from "lodash/keys";
import { cartTimeout } from "$config/config";

type Action = CartTimeoutAction;
export type CartTimeoutState = {
    [eventId: string]: {
        timeout: number;
        cartId?: string;
    };
};
export const cartTimeoutReducer = (state: CartTimeoutState = {}, action: Action) => {
    switch (action.type) {
        case SET_CART_TIMEOUT:
            return decrementCartTimeout(state);
        case DELETE_TIMEOUT:
            return deleteTimeout(state, action);
        case INIT:
            return initTimeout(state, action);
        case SET_CART_ID_INIT:
            return setCartIdForTimeout(state, action);
        default:
            return state;
    }
};

const decrementCartTimeout = (state: CartTimeoutState) => {
    const updatedState: CartTimeoutState = {};
    _forEach(_keys(state), (key) => {
        if (state[key].timeout - 1 >= 0) {
            updatedState[key] = { ...state[key], timeout: state[key].timeout - 1 };
        } else {
            updatedState[key] = state[key];
        }
    });

    return updatedState;
};

const deleteTimeout = (state: CartTimeoutState, action: { eventId: string }) => {
    const updatedState: CartTimeoutState = {};
    _forEach(_keys(state), (key) => {
        if (key !== action.eventId) {
            updatedState[key] = state[key];
        }
    });

    return updatedState;
};

const initTimeout = (state: CartTimeoutState, action: { eventId: string }) => {
    return {
        ...state,
        [action.eventId]: { ...state[action.eventId], timeout: getSecondsFromMinutes(cartTimeout.time) },
    };
};

const setCartIdForTimeout = (state: CartTimeoutState, action: { eventId: string; cartId: string }) => {
    return {
        ...state,
        [action.eventId]: { ...state[action.eventId], cartId: action.cartId },
    };
};

const SECONDS_IN_MINUTES = 60;
const getSecondsFromMinutes = (minutes: number) => {
    return minutes * SECONDS_IN_MINUTES;
};
