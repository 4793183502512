import _find from "lodash/find";
import _filter from "lodash/filter";
import _sortBy from "lodash/sortBy";
import _each from "lodash/each";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import { LocalStorage } from "$constants/LocalStorage";
import { TicketCart } from "$models/types";
import { IStep } from "./IStep";
import PaymentIcon from "@material-ui/icons/Payment";
import { AttendeeWithRedux, Payment } from "$components/core";

//offset for attendee collection in cart from index in stepper
export const ATTENDEE_INDEX_OFFSET = 0;
export const ATTENDEE_INDEX_BACKOFFSET = 1;

export const DefaultPaymentStep: IStep = {
    label: "Payment",
    index: 0,
    labelComponent: PaymentIcon,
    component: Payment,
};

export const extractActiveStepIndex = (currentStep: string | null) => {
    return currentStep == null ? 0 : JSON.parse(currentStep).index;
};

export const getActiveStepFromLocalStorage = () => {
    return localStorage.getItem(LocalStorage.CURRENT_STEP);
};

export const removeActiveStepFromLocalStorage = () => {
    localStorage.removeItem(LocalStorage.CURRENT_STEP);
};

export const setActiveStepToLocalStorage = (step: IStep) => {
    localStorage.setItem(LocalStorage.CURRENT_STEP, JSON.stringify(step));
};

export const createStepperLabels = (tickets: TicketCart[], eventId: string): IStep[] => {
    const stepperLabels: IStep[] = [];

    const ticketsForEvent = _filter(tickets, (ticket) => ticket.event.id === eventId);

    for (let i = ATTENDEE_INDEX_OFFSET; i < ticketsForEvent.length + ATTENDEE_INDEX_OFFSET; i++) {
        const { ticket } = ticketsForEvent[i - ATTENDEE_INDEX_OFFSET];
        stepperLabels.push({
            label: ticket.title,
            ticketId: ticket.price?.id,
            attendeeType: ticket.attendeeType,
            component: AttendeeWithRedux,
            index: 0,
            labelComponent: HowToRegIcon,
        });
    }

    const sortedStepperLabels = _sortBy(stepperLabels, (stepper) => stepper.label);
    _each(sortedStepperLabels, (stepper, index) => {
        stepper.index = index;
    });

    sortedStepperLabels.push({
        label: "Payment",
        component: Payment,
        index: stepperLabels.length,
        labelComponent: PaymentIcon,
    });

    return sortedStepperLabels;
};

export const findAttendee = (ticketCarts: TicketCart[], index: number, eventId: string): TicketCart => {
    return _find(
        ticketCarts,
        (ticketCart) => ticketCart.checkoutIndex === index && ticketCart.event.id === eventId
    )!;
};
