import { AttendeeWithRedux, Buyer } from "$components/core";
import { useCheckoutSelectors, useTicketCartSelector } from "$redux/selectors";
import { useCheckoutActionCreators } from "./useCheckoutActionsCreators";
import { validationBuyer, validationAttendees } from "./validation";
import { findAttendee } from "./checkoutHelper";

type ValidationProps = {
    eventId: string;
};
export const useValidations = ({ eventId }: ValidationProps) => {
    const { buyerModel, activeStep } = useCheckoutSelectors(eventId);
    const { ticketsCart } = useTicketCartSelector(eventId);
    const { setValidationBuyer, setValidationAttendee } = useCheckoutActionCreators();

    const validate = (component?: React.ComponentType | null): boolean => {
        if (typeof component === "undefined" || component == null) {
            return true;
        }

        if (component === Buyer) {
            const isValid = validationBuyer(buyerModel);
            setValidationBuyer(buyerModel);

            return isValid;
        }

        if (component === AttendeeWithRedux) {
            const ticketCart = findAttendee(ticketsCart, activeStep.index!, eventId);
            const isValid = validationAttendees(ticketCart.attendee!);
            setValidationAttendee(ticketCart, eventId!);

            return isValid;
        }

        return true;
    };

    return { validate };
};
