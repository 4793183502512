import React, { useEffect, useReducer, useState } from "react";
import { authenticationService } from "$services/core";
import { reducer, initial, Action } from "./reducer";
import { bindActionCreators } from "$helper/bindActionCreators";
import * as LoginActionCreators from "./actions";
import { useLocation } from "react-router-dom";
import qs from "qs";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { useMessage } from "$helper/useMessage";
import { isValidEmail, ApiErrors } from "@djordjeandjelkovic/medgress_common_react_modules";
import { userProfile } from "$config/config";

interface IState {
    isLoading: boolean;
    email: IField;
    password: IField;
    returnUrl: string;
}

interface IActions {
    handleLogin: () => void;
    handleGoogleResponse: (response: any) => void;
    handleGoogleFailureResponse: () => void;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleEnterKey: (event: React.KeyboardEvent) => void;
}

interface IField {
    value: string;
    error: string | null;
}

export interface IFormState {
    email: IField;
    password: IField;
}

interface StateProps {
    useRedirect: boolean;
}

export const useLogin = (props: StateProps): [IState, IActions] => {
    const [{ email, password }, dispatch] = useReducer(reducer, initial);
    const [isLoading, setLoading] = useState(false);
    const [returnUrl, setReturnUrl] = useState("");
    const { useRedirect } = props;
    const { showError } = useMessage();

    const location = useLocation();
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    const user = authenticationService.getUser();
    const getLiteral = useLiterals();

    const { setEmail, setPassword } = bindActionCreators<Action>(LoginActionCreators, dispatch);

    useEffect(() => {
        if (!returnUrl) {
            if (user !== null) {
                window.location.href = "/";
            } else {
                return;
            }
        }

        window.location.reload();
    }, [returnUrl, user]);

    const validateLoginForm = () => {
        let isValid = true;
        if (!email.value || !isValidEmail(email.value)) {
            showError(getLiteral("login.error.invalid_email"));
            isValid = false;
        }

        if (
            password.value.length < userProfile.passwordLengthMin ||
            password.value.length > userProfile.passwordLengthMax
        ) {
            showError(
                getLiteral(
                    "login.error.invalid_password",
                    userProfile.passwordLengthMin,
                    userProfile.passwordLengthMax
                )
            );
            isValid = false;
        }

        return isValid;
    };

    const handleLogin = async () => {
        if (!validateLoginForm()) {
            return;
        }

        setLoading(true);

        authenticationService
            .login(email.value, password.value)
            .then(() => {
                if (useRedirect) {
                    setReturnUrl(query.returnUrl as string);
                } else {
                    window.location.reload();
                }
            })
            .catch((error) => {
                showError(getLiteral(error));
                setEmail(email.value, ApiErrors[error]);
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleGoogleResponse = async (response: any) => {
        setLoading(true);

        try {
            await authenticationService.loginWithGoogle(response.tokenId);

            if (useRedirect) {
                setReturnUrl(query.returnUrl as string);
            } else {
                window.location.reload();
            }
        } catch (error) {
            showError(getLiteral("engine.response." + error));
            setEmail(email.value, ApiErrors[error]);
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleFailureResponse = () => {
        console.error("Invalid response from Google.");
        showError(getLiteral("login.error.invalid_email_or_password"));
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        switch (event.target.name) {
            case "email":
                setEmail(event.target.value);
                break;
            case "password":
                setPassword(event.target.value);
                break;
        }
    };

    const handleEnterKey = async (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            await handleLogin();
        }
    };

    return [
        { isLoading, email, password, returnUrl },
        {
            handleLogin,
            handleGoogleResponse,
            handleGoogleFailureResponse,
            handleChange,
            handleEnterKey,
        },
    ];
};
