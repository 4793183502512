import * as api from "$helper/api";
import { TicketModel, TicketDTO } from "@djordjeandjelkovic/medgress_common_react_modules";
import { mapToTicketDTO } from "$models/mappings/ticket.mapping"

const getTickets = async (acronym: string): Promise<TicketDTO[]> => {
    const ticketsList = await api.get<TicketModel[]>("/tickets/events/acronym/" + acronym);
    if (ticketsList === null) {
        return [];
    }
    return ticketsList.map(ticket => {
        return mapToTicketDTO(ticket);
    });
}

export const ticketService = {
    getTickets
}
