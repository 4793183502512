import { IBuyer } from "$models/Checkout";
import { Error } from "$models/Error";
import { SET_EMAIL, SET_NAME, VALIDATION } from "$redux/actions/Buyer/buyerActionTypes";

const initialValue: Error<IBuyer> = {
    name: {
        value: "",
        error: false,
        message: "",
    },
    email: {
        value: "",
        error: false,
        message: "",
    },
};

export function buyerReducer(state: Error<IBuyer> = initialValue, action: any): Error<IBuyer> {
    switch (action.type) {
        case SET_EMAIL:
            return { ...state, email: { ...state.email, value: action.email } };
        case SET_NAME:
            return { ...state, name: { ...state.name, value: action.name } };
        case VALIDATION:
            return { ...action.buyer };
        default:
            return state;
    }
}
