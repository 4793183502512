import React, { useCallback, useState } from "react";
import { Redirect } from "react-router-dom";
import { SignUpForm } from "./SignUpForm";

interface Props {
    open: boolean;
    onClose: () => void;
}

const SignUp = (props: Props) => {
    const [signUp, setSignUp] = useState(false);
    const { open, onClose: handleClose } = props;

    const handleSignUp = useCallback(() => {
        setSignUp(true);
    }, [setSignUp]);

    if (!signUp) {
        return <SignUpForm open={open} handleClose={handleClose} onSignUp={handleSignUp} />;
    }

    return (
        <Redirect
            to={{
                pathname: "/sign-up/registration-complete",
            }}
        />
    );
};

export { SignUp };
