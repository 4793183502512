import { useLiterals } from "$hooks/Translate/useLiterals";

export const useTicketsUtils = () => {
    const literals = useLiterals();

    const showTotalSum = (sum: string | undefined, currency: string) => {
        if (!sum) {
            return "";
        }
        return sum === "0.00" ? literals("ticket_cart.payment.free_ticket") : sum + " " + currency;
    };

    return { showTotalSum };
}
