import { useMemo } from "react";
import { ITicketCount } from "./useAddTickets";
import { EventDTO } from "@djordjeandjelkovic/medgress_common_react_modules";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { useMessage } from "$helper/useMessage";
import { utils } from "$helper/utils";
import { attendeeService, authenticationService, ticketCartService } from "$services/core";
import { TicketCart } from "$models/types";
import { AttendeeField, ErrorType, IAttendee } from "@djordjeandjelkovic/medgress_common_react_modules";
import { IError } from "$models/Error";
import _map from "lodash/map";
import { useTicketCartSelector } from "$redux/selectors";
import { useCart } from "./useCart";

type CreateTicketsProps = {
    ticketTypes: ITicketCount[];
    event: EventDTO;
};
export const useCreateTickets = ({ ticketTypes, event }: CreateTicketsProps) => {
    const { showError } = useMessage();
    const getLiteral = useLiterals();
    const { cartId, ticketsInCart } = useTicketCartSelector(event.id);
    const cart = useCart();

    const user = useMemo(() => authenticationService.getUser(), []);

    const createCart = async () => {
        if (cartId) {
            return cartId;
        }
        const c = await cart.create(event.id, user?.id);

        return c?.id;
    };

    const createTickets = async () => {
        const ticketsForCart: TicketCart[] = [];

        await _deleteAttendees();

        const cId = await createCart();

        await utils.forEachAsync(ticketTypes, async (ticketType: ITicketCount) => {
            for (let i = 0; i < ticketType.count; i++) {
                const attendee = await _createAttendee(ticketType.ticket.price?.id!, event.id, cId!);
                if (attendee) {
                    ticketsForCart.push({
                        ticket: ticketType.ticket,
                        event,
                        attendee,
                    });
                }
            }
        });

        return ticketsForCart;
    };

    const createTicketOfType = async (id: string) => {
        const ticketsForCart: TicketCart[] = [];

        const cId = await createCart();

        await utils.forEachAsync(ticketTypes, async (ticketType: ITicketCount) => {
            if (ticketType.ticket.id !== id) {
                return;
            }

            for (let i = 0; i < ticketType.count; i++) {
                const attendee = await _createAttendee(ticketType.ticket.price?.id!, event.id, cId!);
                if (attendee) {
                    ticketsForCart.push({
                        ticket: ticketType.ticket,
                        event,
                        attendee,
                    });
                }
            }
        });

        return ticketsForCart;
    };

    const _createAttendee = async (ticketId: string, eventId: string, cartId: string) => {
        try {
            const attendee = _createAttendeeInCart(await attendeeService.getAttendeeFieldsWithError(eventId));
            const response = await ticketCartService.addAttendeeToCart(
                cartId,
                eventId,
                ticketId,
                _toIAttendee(attendee)
            );
            attendee.id.value = response.id!;
            return attendee;
        } catch (error) {
            console.error(error);
            showError(getLiteral(`engine.response.${error}`));
        }

        return null;
    };

    const _deleteAttendees = async () => {
        if (!ticketsInCart[event.id]) {
            return;
        }
        await utils.forEachAsync(ticketsInCart[event.id].tickets, async (ticket) => {
            try {
                await ticketCartService.removeAttendeeFromCart(
                    ticketsInCart[event.id].cartId!,
                    ticket.attendee!.id.value
                );
            } catch (error) {
                showError(getLiteral("checkout.error_message"));
                console.error("Problem with removing attendee.", error);
            }
        });
    };

    return { createTickets, createTicketOfType };
};

const _createAttendeeInCart = (attendeeFields: IError<AttendeeField>[]): ErrorType<IAttendee, ""> => {
    return {
        id: { value: "" },
        firstName: { value: "" },
        lastName: { value: "" },
        fields: attendeeFields,
    };
};

const _toIAttendee = (attendee: ErrorType<IAttendee, ""> | undefined): IAttendee => ({
    id: attendee?.id?.value || null,
    firstName: attendee?.firstName?.value || "",
    lastName: attendee?.lastName?.value || "",
    fields: _map(attendee?.fields || [], (field) => field.value),
});
