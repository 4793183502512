import { TicketDTO, TicketStatusEnum } from "@djordjeandjelkovic/medgress_common_react_modules";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { DECIMAL_PLACES } from "$constants/Numbers";

export const useTickets = () => {
    const getLiteral = useLiterals();
    const getPriceInfo = (ticket: TicketDTO) => {
        if (ticket.status === TicketStatusEnum.AVAILABLE) {
            return ticket.price?.amount === 0
                ? getLiteral("event_details.ticket.free")
                : ticket.currency + " " + ticket.price?.amount.toFixed(DECIMAL_PLACES);
        }

        if (ticket.status === TicketStatusEnum.NO_AVAILABLE) {
            return getLiteral("event_details.ticket.not_available_tickets");
        }

        if (ticket.status === TicketStatusEnum.SOLD_OUT) {
            return getLiteral("event_details.ticket.sold_out");
        }

        console.log("Unknown ticket price info: " + JSON.stringify(ticket));
        return getLiteral("event_details.ticket.unknown");
    };

    return { getPriceInfo };
};
