import _map from "lodash/map";
import _reduce from "lodash/reduce";
import _find from "lodash/find";
import * as api from "$helper/api";

import { IError, Error } from "$models/Error";
import {
    IAttendeeField,
    attendeeFieldDTOToIAttendeeField,
    AttendeeFieldDTO,
    IAttendee,
    AttendeeDTO,
    Attendee,
} from "@djordjeandjelkovic/medgress_common_react_modules";

const getAttendeeFieldsWithError = async (eventId: string): Promise<IError<IAttendeeField>[]> => {
    const data = await api.get<AttendeeFieldDTO[]>(`/fields/events/${eventId}`);
    return _map(await attendeeFieldDTOToIAttendeeField(data), (attendee) => ({
        value: attendee,
        error: false,
        message: "",
    }));
};

const getAttendeeFields = async (eventId: string): Promise<IAttendeeField[]> => {
    const data = await api.get<AttendeeFieldDTO[]>(`/fields/events/${eventId}`);
    return attendeeFieldDTOToIAttendeeField(data);
};

const get = async (
    attendeeId: string,
    optionals?: { date: string; hash: string }
): Promise<Error<IAttendee, "">> => {
    const data = await api.get<AttendeeDTO>(
        `/attendees/${attendeeId}?` +
            (optionals && optionals.date && `d=${optionals.date}`) +
            (optionals && optionals.hash && `&h=${optionals.hash}`)
    );
    const fields = await getAttendeeFields(data.eventId!);
    return AttendeeDTOMapper.toErrorIAttendee(data, fields);
};

const update = async (
    attendee: Error<IAttendee, "">,
    optionals?: { date: string; hash: string }
): Promise<void> => {
    return api.put(
        `/attendees/${attendee.id.value}?` +
            (optionals && optionals.date && `d=${optionals.date}`) +
            (optionals && optionals.hash && `&h=${optionals.hash}`),
        AttendeeDTOMapper.toAttendee(attendee)
    );
};

class AttendeeDTOMapper {
    static toErrorIAttendee(attendee: AttendeeDTO, fields: IAttendeeField[]): Error<IAttendee, ""> {
        const attendeeFields = _reduce(
            fields,
            (acc, n) => {
                acc.push({
                    value: { ...n, value: _find(attendee.fields, (f) => f.fieldId === n.id)?.value },
                });
                return acc;
            },
            [] as IError<IAttendeeField>[]
        );

        return {
            firstName: { value: attendee.firstName! },
            lastName: { value: attendee.lastName! },
            id: { value: attendee.id! },
            badgeId: { value: attendee.badgeId! },
            dubaiTourismId: { value: attendee.dubaiTourismId! },
            eventId: { value: attendee.eventId! },
            attendeeType: { value: String(attendee.attendeeType!) },
            status: { value: String(attendee.status!) },
            fields: attendeeFields,
        };
    }

    static toAttendee(attendee: Error<IAttendee, "">): Attendee {
        return {
            firstName: attendee.firstName.value,
            lastName: attendee.lastName.value,
            id: attendee.id.value,
            badgeId: attendee.badgeId?.value || null,
            dubaiTourismId: attendee.dubaiTourismId?.value || null,
            eventId: attendee.eventId?.value || null,
            attendeeType: Number(attendee.attendeeType?.value),
            status: Number(attendee.status?.value),

            fields: _map(attendee.fields, (f) => ({
                id: null,
                value: f.value.value || null,
                attendeeId: attendee.id.value || null,
                field: { id: f.value.id },
            })),
        };
    }

    static toAttendeeDTO(attendee: Error<IAttendee, "">): AttendeeDTO {
        return {
            firstName: attendee.firstName.value,
            lastName: attendee.lastName.value,
            id: attendee.id.value,
            badgeId: attendee.badgeId?.value,
            dubaiTourismId: attendee.dubaiTourismId?.value,
            eventId: attendee.eventId?.value,
            attendeeType: Number(attendee.attendeeType?.value),

            fields: _map(attendee.fields, (f) => ({ value: f.value.value, fieldId: f.value.id })),
        };
    }
}

export const attendeeService = {
    getAttendeeFieldsWithError,
    getAttendeeFields,
    get,
    update,
};
