import _map from "lodash/map";
import _find from "lodash/find";
import { useSelector } from "react-redux";
import { ReduxStore } from "$redux/reducers";
import { ErrorType, IAttendee } from "@djordjeandjelkovic/medgress_common_react_modules";
import { Error } from "$models/Error";
import { createSelector } from "reselect";
import { TicketCart } from "$models/types";

const toIAttendee = (attendeeField: Error<IAttendee> | undefined): IAttendee => ({
    id: attendeeField?.id?.value || null,
    firstName: attendeeField?.firstName?.value || "",
    lastName: attendeeField?.lastName?.value || "",
    fields: _map(attendeeField?.fields || [], (field) => field.value),
});


const attendeeSelector = createSelector(
    (store: ReduxStore) => store,
    (_: any, props: { index: number; eventId: string }) => props,
    (store: ReduxStore, props: { index: number; eventId: string }): IAttendee =>
        toIAttendee(findAttendee(store.ticketsCart[props.eventId]?.tickets || [], props.index)?.attendee)
);

const attendeeModelSelector = createSelector(
    (store: ReduxStore) => store,
    (_: any, props: { index: number; eventId: string }) => props,
    (store: ReduxStore, props: { index: number; eventId: string }): ErrorType<IAttendee, ""> | undefined =>
        findAttendee(store.ticketsCart[props.eventId]?.tickets || [], props.index)?.attendee
);

export const useAttendeeSelector = (eventId: string, index: number) => {
    const attendee = useSelector<ReduxStore, IAttendee>((store) =>
        attendeeSelector(store, { eventId, index })
    );

    const attendeeModel = useSelector<ReduxStore, Error<IAttendee, ""> | undefined>((store) =>
        attendeeModelSelector(store, { eventId, index })
    );

    return { attendee, attendeeModel };
};

function findAttendee(ticketCarts: TicketCart[], index: number) {
    return _find(ticketCarts, (ticketCart) => ticketCart.checkoutIndex === index);
}
