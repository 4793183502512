import _map from "lodash/map";
import _filter from "lodash/filter";
import { useSelector } from "react-redux";
import { ReduxStore } from "$redux/reducers";
import { TicketCart } from "@djordjeandjelkovic/medgress_common_react_modules";
import { IBuyer } from "$models/Checkout";
import { Error } from "$models/Error";
import { createSelector } from "reselect";
import { IStep } from "$pages/Checkout/IStep";

const ticketsSelector = createSelector(
    (store: ReduxStore) => store,
    (_: any, eventId: string) => eventId,
    (store: ReduxStore, eventId: string) => store.ticketsCart[eventId]?.tickets || []
);


export const useCheckoutSelectors = (eventId?: string) => {
    const numberOfTickets = useSelector<ReduxStore, number>((store) =>
        typeof eventId === "undefined" ? 0 : ticketsSelector(store, eventId).length
    );

    const tickets = useSelector<ReduxStore, TicketCart[] | undefined>((store) =>
        typeof eventId === "undefined" ? undefined : ticketsSelector(store, eventId)
    );

    const eventCartHasAttendee = useSelector<ReduxStore, string[]>((store) =>
        _map(
            _filter(store.checkout.attendees, (attendee) => attendee.attendeeFields.length > 0),
            (attendee) => attendee.eventId
        )
    );

    const buyer = useSelector<ReduxStore, IBuyer>((store) => ({
        name: store.checkout.buyerInfo.name.value,
        email: store.checkout.buyerInfo.email.value,
    }));

    const buyerModel = useSelector<ReduxStore, Error<IBuyer>>((store) => store.checkout.buyerInfo);

    const activeStep = useSelector<ReduxStore, IStep>((store) => store.checkout.activeStep);

    return {
        numberOfTickets,
        tickets,
        buyer,
        buyerModel,
        eventCartHasAttendee,
        activeStep,
    };
};
