import * as api from "$helper/api";

import { Company } from "@djordjeandjelkovic/medgress_common_react_modules";

const getByAcronym = (acronym: string): Promise<Company> => {
    return api.get<Company>(`/companies/acronym/${acronym}`);
}

const getList = (): Promise<Company[]> => {
    return api.get<Company[]>("/companies");
}

export const companyService = {
    getByAcronym,
    getList
};
