import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles({
    ticketsCount: {
        textAlign: 'right',
        width: '100%'
    },
    ticketSelect: {
        paddingLeft: 10
    },
    ticketButton: {
        width: '100%',
    },
});
