import React from "react";

import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";

import { Error } from "$models/Error";
import { IAttendee } from "@djordjeandjelkovic/medgress_common_react_modules";
import { Attendee } from "$components/core";

import { useLiterals } from "$hooks/Translate/useLiterals";
import TicketFooterActions from "./TicketFooterActions";

import { useStyles } from "./styles";

type CollapseAttendeeProps = { 
    in: boolean;
    attendee: Error<IAttendee>;
    resend: {
        isLoading: boolean;
        attendeeId: string;
        onResend: (attendeeId: string) => void;
    };
    view: {
        onView: React.MouseEventHandler<HTMLButtonElement>;
    };
    download: {
        eventId: string;
        attendeeId: string;
    };
    showTicketActions: boolean;
};

const CollapseAttendee = (props: CollapseAttendeeProps) => {
    const { in: inCollapse, attendee, resend, view, download, showTicketActions } = props;
    const classes = useStyles();
    const getLiteral = useLiterals();

    if (attendee.id.value.startsWith("deleted")) {
        return (
            <Collapse in={inCollapse} unmountOnExit>
                <CardContent className={classes.cardContent}>
                    <Typography variant="body2">
                        {getLiteral("history_purchase_details.attendee_does_not_exist")}
                    </Typography>
                </CardContent>
            </Collapse>
        );
    }

    return (
        <Collapse in={inCollapse} unmountOnExit>
            <CardContent className={classes.cardContent}>
                <Attendee attendee={attendee} attendeeType={Number(attendee.attendeeType?.value)} readOnly disabled />
                <TicketFooterActions 
                    resend={resend}
                    view={view}
                    download={download}
                    showTicketActions={showTicketActions}
                />
            </CardContent>
        </Collapse>
    );
};

export default React.memo(
    CollapseAttendee,
    (prevProps, nextProps) => prevProps.in === nextProps.in && prevProps.attendee === nextProps.attendee
);
