import React from "react";
import Button from "@material-ui/core/Button";

import { SpinnerButton } from "@djordjeandjelkovic/medgress_common_react_modules";
import { engineUrl } from "$helper/api";

import { useStyles } from "./styles";

type TicketFooterActionsProps = {
    resend: {
        isLoading: boolean;
        attendeeId: string;
        onResend: (attendeeId: string) => void;
    };
    view: {
        onView: React.MouseEventHandler<HTMLButtonElement>;
    };
    download: {
        eventId: string;
        attendeeId: string;
    };
    showTicketActions: boolean;
};

const TicketFooterActions = (props: TicketFooterActionsProps) => {
    const { resend, view, download, showTicketActions } = props;
    const classes = useStyles();

    if(!showTicketActions) {
        return null;
    }

    return (
        <div className={classes.ticketActions}>
            <SpinnerButton
                isLoading={resend.isLoading}
                onClick={() => resend.onResend(resend.attendeeId)}
                title="Resend"
                ButtonProps={{
                    color: "primary",
                    variant: "outlined",
                    size: "medium",
                }}
            />
            <Button color="primary" variant="outlined" onClick={view.onView} className={classes.ticketAction}>
                View ticket
            </Button>
            <Button
                color="primary"
                variant="outlined"
                target="_blank"
                href={`${engineUrl()}/ticket-carts/download/events/${download.eventId}/attendees/${
                    download.attendeeId
                }`}>
                Download ticket
            </Button>
        </div>
    );
};

export default TicketFooterActions;
