import { useMessage } from "$helper/useMessage";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { useAttendeeSelector, useCheckoutSelectors } from "$redux/selectors";
import ticketCartService from "$services/ticketcart.service";
import { removeActiveStepFromLocalStorage } from "./checkoutHelper";
import { useActiveStep } from "./usaActiveStep";
import { useCheckoutActionCreators } from "./useCheckoutActionsCreators";
import _find from "lodash/find";
import { useTicketCartSelector } from "$redux/selectors/useTicketCartSelector";
import { useCreditCardInfoState } from "$components/Payment/CreditCardInfo/creditCardInfoState";

type RemoveTicketProps = {
    eventId: string;
    cartId: string | null;
};
export const useRemoveTicket = ({ eventId, cartId }: RemoveTicketProps) => {
    const { tickets } = useCheckoutSelectors(eventId);
    const { activeStep } = useActiveStep({ eventId });
    const { attendee } = useAttendeeSelector(eventId, activeStep.index!);
    const { showError } = useMessage();
    const getLiteral = useLiterals();
    const { removeTicket } = useCheckoutActionCreators();
    const { ticketsInCart } = useTicketCartSelector(null);
    const [, creditCardState] = useCreditCardInfoState();

    const remove = async (id: string) => {
        const ticket = _find(tickets, (ticketInCart) => ticketInCart.ticket.price!.id === id);
        if (!ticket) {
            return;
        }

        try {
            const hasOneTicket = tickets!.length === 1;
            if (hasOneTicket) {
                removeActiveStepFromLocalStorage();
            }

            if (isLastTicket(eventId)) {
                creditCardState.reset();
            }

            if (attendee!.id) {
                try {
                    await ticketCartService.removeAttendeeFromCart(cartId!, attendee!.id);
                } catch (error) {
                    showError(getLiteral("checkout.error_message"));
                    console.error("Problem with removing attendee.");
                }
            }
            removeTicket(ticket);
        } catch (error) {
            console.error(error);
        }
    };

    const isLastTicket = (eventId: string) => ticketsInCart[eventId].tickets.length === 1;

    return { remove };
};
