import React from "react";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { TicketCount } from "./TicketCount";
import { EventChange } from "@djordjeandjelkovic/medgress_common_react_modules";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { useStyles } from "./styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { AvailableTickets } from "./AvailableTickets";
import { events } from "$config/config";

type TicketProps = {
    title: string;
    total?: number;
    sold: number;
    showAvailableTickets: number;
    id: string;
    onTicketCountChange: (event: EventChange<string>, ticketId: string) => void;
    onAddToCart: (ticketId: string) => void;
    disabled: boolean;
    disabledCount: boolean;
    ticketCount: number;
    price: string;
    isLoading: boolean;
    embeded?: boolean;
    acronym: string;
};

export const Ticket = (props: TicketProps) => {
    const {
        title,
        id,
        disabled,
        ticketCount,
        total,
        sold,
        showAvailableTickets,
        onTicketCountChange,
        onAddToCart,
        disabledCount,
        price,
        isLoading,
        embeded,
        acronym,
    } = props;
    const classes = useStyles();

    return (
        <Grid container>
            <Grid item sm={3}>
                <ReceiptIcon style={{ fontSize: 64 }} className={classes.ticketIcon} />
            </Grid>
            <Grid item sm={9} container>
                <Grid item xs={12} className={classes.ticketTitleContainer}>
                    <Typography variant="subtitle1" className={classes.ticketTitle}>
                        {title.toUpperCase()}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.ticketPrice}>
                        {price}
                    </Typography>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item xs={6}>
                        <TicketCount
                            disabled={disabledCount || isLoading}
                            ticketId={id}
                            count={ticketCount}
                            onChange={onTicketCountChange}
                            name={id}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AddToCartButton
                            onAddToCart={onAddToCart}
                            disabled={disabled}
                            isLoading={isLoading}
                            embeded={Number(embeded)}
                            id={id}
                            acronym={acronym}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <AvailableTickets
                        total={total}
                        sold={sold}
                        disabledCount={disabledCount}
                        showAvailableTickets={showAvailableTickets}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

enum EMBEDED {
    FALSE = 0,
    TRUE = 1,
}

type AddToCartButtonProps = {
    onAddToCart: (id: string) => void;
    disabled: boolean;
    isLoading: boolean;
    id: string;
    embeded?: EMBEDED;
    acronym: string;
};
const AddToCartButton = (props: AddToCartButtonProps) => {
    const {
        onAddToCart,
        isLoading,
        disabled,
        id,
        embeded,
        acronym,
    } = props;
    const classes = useStyles();
    const getLiteral = useLiterals();

    const buttonProps: { [key: number]: any } = {
        [EMBEDED.FALSE]: {
            className: classes.addToCart,
            onClick: () => onAddToCart(id),
            disabled: disabled || isLoading,
            color: "primary",
            variant: "contained",
        },
        [EMBEDED.TRUE]: {
            className: classes.addToCart,
            disabled: disabled || isLoading,
            color: "primary",
            target: "_blank",
            href: `${events.url}/events/${acronym}`,
            variant: "contained",
        },
    };

    return (
        <Button {...buttonProps[embeded || EMBEDED.FALSE]}>
            {getLiteral("event_details.ticket.button.add_to_cart")}
        </Button>
    );
};
