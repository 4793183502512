import * as api from "$helper/api";
import { IPayment, IPaymentCreditCard } from "$redux/reducers/paymentReducers";
import {
    IPurchaseHistoryDetailsDTO,
    IPurchaseHistoryDTO,
    ITicketCartDTO,
    IAttendee,
    AttendeeDTO,
    attendeesToAttendeeFieldDTO,
    attendeeToAttendeeFieldDTO,
    ICrediCardDTO,
    SendTicketsTo,
} from "@djordjeandjelkovic/medgress_common_react_modules";

const createCartNotLoggedIn = (name: string, email: string): Promise<ITicketCartDTO> => {
    return api.post<ITicketCartDTO, ITicketCartDTO>("/ticket-carts/", { name, email } as ITicketCartDTO);
};

const createCartLoggedIn = (userId?: string): Promise<ITicketCartDTO> => {
    return api.post<ITicketCartDTO, ITicketCartDTO>("/ticket-carts/", { userId } as ITicketCartDTO);
};

const addAttendeesToCart = (cartId: string, eventId: string, attendees: IAttendee[]) => {
    return api.post<AttendeeDTO[], void>(
        `/ticket-carts/${cartId}/add-attendees/`,
        attendeesToAttendeeFieldDTO(eventId, attendees)
    );
};

const addAttendeeToCart = (cartId: string, eventId: string, ticketId: string, attendee: IAttendee) => {
    return api.post<AttendeeDTO, AttendeeDTO>(
        `/ticket-carts/${cartId}/tickets/${ticketId}/attendees`,
        attendeeToAttendeeFieldDTO(eventId, attendee)
    );
};

const removeAttendeeFromCart = (cartId: string, attendeeId: string) => {
    return api.remove<string>(`ticket-carts/${cartId}/attendees/${attendeeId}`);
};

const purchaseHistories = () => {
    return api.get<IPurchaseHistoryDTO[]>(`ticket-carts/purchases`);
};

const purchaseHistoryDetails = (cartId: string) => {
    return api.get<IPurchaseHistoryDetailsDTO[]>(`ticket-carts/${cartId}/purchase`);
};

const finishProcessCart = (cartId: string) => {
    return api.post<{}, {}>(`ticket-carts/${cartId}/finish-process-cart`, {});
};

const stripePayment = (cartId: string, card: IPayment, sendTicketsTo: SendTicketsTo) => {
    let cardInstance: IPaymentCreditCard = card as IPaymentCreditCard;
    const c: ICrediCardDTO = {
        Cvc: cardInstance.cvc,
        ExpireMonth: Number(cardInstance.expireMonth),
        ExpireYear: Number(cardInstance.expireYear),
        Holder: cardInstance.cardHolder,
        Number: cardInstance.cardNumber,
    };
    return api.post<ICrediCardDTO, {}>(
        `ticket-carts/${cartId}/stripe-payment?sendTicketsTo=${sendTicketsTo}`,
        c
    );
};

const payPalPayment = (cartid: string, card: IPayment) => {
    return api.post<{}, {}>("", {});
};

const deleteCart = (cartId: string) => {
    return api.remove<any>(`ticket-carts/${cartId}`);
};

const resendTicket = (cartId: string, attendeeId: string) => {
    return api.post(`ticket-carts/${cartId}/resend/attendees/${attendeeId}`, {});
};

export default {
    createCartNotLoggedIn,
    createCartLoggedIn,
    addAttendeesToCart,
    addAttendeeToCart,
    removeAttendeeFromCart,
    purchaseHistories,
    purchaseHistoryDetails,
    finishProcessCart,
    stripePayment,
    payPalPayment,
    deleteCart,
    resendTicket,
};
