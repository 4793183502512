import _forEach from "lodash/forEach";

import { Error } from "$models/Error";
import { IBuyer } from "$models/Checkout";

import { IAttendee, isValidEmail } from "@djordjeandjelkovic/medgress_common_react_modules";

export const validationBuyer = (buyer: Error<IBuyer>): boolean => {
    let isValid = true;
    if (buyer.name.value === "") {
        buyer.name.error = true;
        isValid = isValid && false;
    } else {
        buyer.name.error = false;
        isValid = isValid && true;
    }

    if (buyer.email.value === "" || !isValidEmail(buyer.email.value)) {
        buyer.email.error = true;
        isValid = isValid && false;
    } else {
        buyer.email.error = false;
        isValid = isValid && true;
    }

    return isValid;
};

export const validationAttendees = (attendee: Error<IAttendee>): boolean => {
    let isValid = true;

    if (attendee.firstName.value === "") {
        isValid = isValid && false;
        attendee.firstName = { ...attendee.firstName, error: true };
    } else {
        isValid = isValid && true;
        attendee.firstName = { ...attendee.firstName, error: false };
    }
    if (attendee.lastName.value === "") {
        isValid = isValid && false;
        attendee.lastName = { ...attendee.lastName, error: true };
    } else {
        isValid = isValid && true;
        attendee.lastName.error = false;
        attendee.lastName = { ...attendee.lastName, error: false };
    }

    _forEach(attendee.fields, (field, index) => {
        if (field.value.required && field.value.value === "") {
            isValid = isValid && false;
            field.error = true;
            attendee.fields[index] = { ...field, error: true };
        } else {
            isValid = isValid && true;
            attendee.fields[index] = { ...field, error: false };
        }
    });

    return isValid;
};
