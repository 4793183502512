import { useState, useCallback, useEffect } from "react";
import _findIndex from "lodash/findIndex";
import { ticketService } from "$services/core";
import { EventChange, TicketDTO } from "@djordjeandjelkovic/medgress_common_react_modules";
import { TicketStatusEnum } from "$enums/TicketStatusEnum";
import { TicketsProps } from "./TicketsProps";
import { useTicketsActionCreators } from "./useTicketsActionCreators";
import { TicketActions, TicketState } from "./TicketReturn";
import { ITicketCount, useAddTickets } from "./useAddTickets";

export const useTicketsState = (props: TicketsProps): [TicketState, TicketActions] => {
    const { acronym, event } = props;
    const [tickets, setTickets] = useState<TicketDTO[] | undefined>(undefined);
    const [ticketsCount, setTicketsCount] = useState<ITicketCount[]>([]);
    const { initTimeout } = useTicketsActionCreators({ event });
    const { addTicket, addTickets } = useAddTickets({ ticketsCount, event });
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            if (!acronym) {
                return;
            }

            const ticketsList = await ticketService.getTickets(acronym);
            setTickets(ticketsList);
        };
        fetch();
    }, [acronym]);

    const handleChangeTicketCount = useCallback(
        (event: EventChange<string>, ticketId: string) => {
            setTicketsCount((previousState: ITicketCount[]) => {
                if (tickets === undefined) {
                    return previousState;
                }

                const selectedTicket = tickets.find((ticketItem) => ticketItem.id === ticketId);
                if (selectedTicket === undefined) {
                    console.log("Something went wrong while changing ticket count. TicketId not found.");
                    return previousState;
                }

                let newTicketsCountList = [...previousState];
                const existingTicket = newTicketsCountList.find((ticket) => ticket.ticket.id === ticketId);
                if (existingTicket === undefined) {
                    return [...previousState, { ticket: selectedTicket, count: Number(event.target.value) }];
                }

                existingTicket.count = Number(event.target.value);
                return newTicketsCountList;
            });
        },
        [tickets]
    );

    const hasSelectedTicket = (id?: string): boolean => {
        if (!id) {
            return _findIndex(ticketsCount, (ticket) => ticket.count > 0) !== -1;
        }

        return _findIndex(ticketsCount, (ticket) => ticket.ticket.id === id && ticket.count > 0) !== -1;
    };

    const isDisabledTicketsCount = useCallback((ticket: TicketDTO) => {
        return ticket.status === TicketStatusEnum.NO_AVAILABLE || ticket.status === TicketStatusEnum.SOLD_OUT;
    }, []);

    const addTicketsToCart = async (id?: string) => {
        if (!tickets) {
            return;
        }

        initTimeout(event.id);

        
        try {
            setLoading(true);

            if(id) {
                await addTicket(id);
            } else {
                await addTickets();
            }
        } catch(error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    return [
        { tickets, ticketsCount, isLoading },
        {
            handleChangeTicketCount,
            addTicketsToCart,
            hasSelectedTicket,
            isDisabledTicketsCount,
        },
    ];
};
