import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "400px",
            margin: "0 auto",
            zIndex: 10,
            position: "relative",
        },
        gridRoot: {
            alignContent: "center",
            height: "100%",
            overflow: "hidden",
        },
        gridItem: {
            height: "450px",
        },
        successCreate: {
            height: "350px",
            width: "100%",
        },
        successIconWrapper: {
            height: "200px",
            backgroundColor: theme.palette.primary.main,
        },
        successIcon: {
            fontSize: "160px",
            position: "relative",
            top: "50%",
            left: "50%",
            marginTop: "-80px",
            marginLeft: "-80px",
            color: "#fff",
        },
        successCreateTextWrapper: {
            height: "150px",
        },
        successCreateText: {
            position: "relative",
            top: "50%",
            marginTop: "-47px",
            textAlign: "center",
        },
        signUpPageWrapper: {
            position: "relative",
            overflow: "hidden",
            height: "750px",
        },
    })
);
