import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

import clsx from "clsx";

import { useQueryParams } from "$hooks/QueryParams";
import { ticketCartService, attendeeService } from "$services/core";
import { PageHeader } from "$components/core";

import { useLiterals } from "$hooks/Translate/useLiterals";
import { HistoryAttendee } from "./Models";
import { createErrorAttendeeArray, processEmptyAttendees } from "./ticket-utils";
import TicketCartAttendees from "./TicketCartAttendees";

import { useStyles } from "./styles";
import { IPurchaseHistoryDetailsDTO } from "@djordjeandjelkovic/medgress_common_react_modules";

const PurchaseHistoryDetails = () => {
    const classes = useStyles();
    const query = useQueryParams(useLocation().search);
    const { cartId } = useParams<{ cartId: string }>();
    const eventId = query.get("eventId");
    const getLiteral = useLiterals();

    const [isLoading, setIsLoading] = useState(false);
    const [attendees, setAttendees] = useState<HistoryAttendee[]>([]);
    const [purchaseHistoryDetails, setPurchaseHistoryDetails] = useState<IPurchaseHistoryDetailsDTO[]>([]);

    useEffect(() => {
        async function fetchHistoryDetails() {
            if (!cartId || !eventId) {
                return;
            }

            try {
                setIsLoading(true);
                const fields = await attendeeService.getAttendeeFields(eventId);
                const historyDetails = await ticketCartService.purchaseHistoryDetails(cartId);
                console.log(historyDetails);
                setPurchaseHistoryDetails(historyDetails);
                processEmptyAttendees(historyDetails);
                const historyAttendees = createErrorAttendeeArray(fields, historyDetails);
                setAttendees(historyAttendees);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        }
        fetchHistoryDetails();
    }, [cartId, eventId]);

    const hasAttendees = attendees.length > 0;
    const subTitle = hasAttendees ? attendees[0].eventName : "";

    if (isLoading)
        return (
            <Paper className={classes.paperHistoryDetails}>
                <CircularProgress className={clsx(classes.loading, classes.loading40)} />
            </Paper>
        );

    return (
        <>
            <PageHeader title={getLiteral("history_purchase_details.title")} subtitle={subTitle} />
            <div className={classes.paperHistoryDetails}>
                <TicketCartAttendees attendees={attendees} cartId={cartId} purchaseHistoryDetails={purchaseHistoryDetails} />
            </div>
        </>
    );
};

export { PurchaseHistoryDetails };
