import React from "react";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import { IStep } from "../IStep";
import { StepIcon } from "../StepIcon";
import _map from "lodash/map";

type AttendeeStepperProps = {
    activeStep: IStep;
    stepperLabels: { label: string; icon: React.ComponentType<any>; index: number; id: string }[];
};

export const AttendeeStepper = (props: AttendeeStepperProps) => {
    const { activeStep, stepperLabels } = props;
    return (
        <Stepper activeStep={activeStep.index} alternativeLabel>
            {_map(stepperLabels, (label) => (
                <Step key={label.id}>
                    <StepLabel StepIconComponent={(props) => <StepIcon {...props} stepIcon={label.icon} />}>
                        {label.label}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
};
