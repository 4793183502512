import { SET_NAME, SET_EMAIL, VALIDATION } from "./buyerActionTypes";
import { BUYER } from "../Checkout/checkoutActionTypes";

import { Error } from "$models/Error";
import { IBuyer } from "$models/Checkout";

export const setName = (name: string) => ({
    type: SET_NAME,
    altType: BUYER,
    name,
});

export const setEmail = (email: string) => ({
    type: SET_EMAIL,
    altType: BUYER,
    email,
});

export const setValidationBuyer = (buyer: Error<IBuyer>) => ({
    type: VALIDATION,
    altType: BUYER,
    buyer,
});
