import React from "react";
import { Divider, Grid, Button } from "@material-ui/core";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import { TicketsLoader, TopBorderContainer } from "$components/core";
import { useStyles } from "./styles";
import { TicketsProps } from "./TicketsProps";
import { useTicketsState } from "./useTicketsState";
import { TicketDTO } from "@djordjeandjelkovic/medgress_common_react_modules";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { useTickets } from "$hooks/Tickets/useTickets";
import { Ticket } from "./Ticket";
import { events } from "$config/config";

const Tickets = (props: TicketsProps) => {
    const { preview, embeded, event } = props;
    const [
        { tickets, ticketsCount, isLoading },
        { handleChangeTicketCount, addTicketsToCart, hasSelectedTicket, isDisabledTicketsCount },
    ] = useTicketsState(props);
    const classes = useStyles();
    const getLiteral = useLiterals();
    const ticketsStatic = useTickets();

    if (!tickets) {
        return <TicketsLoader />;
    }

    return (
        <TopBorderContainer title={getLiteral("event_details.ticket.title")} className={classes.paper}>
            <Grid container>
                <Grid item className={classes.tickets} xs={12}>
                    {tickets.map((ticket: TicketDTO) => (
                        <React.Fragment key={ticket.id}>
                            <Ticket
                                embeded={embeded}
                                title={ticket.title}
                                total={ticket.limit}
                                sold={ticket.soldTickets || 0}
                                showAvailableTickets={ticket.displayLimit}
                                id={ticket.id}
                                onTicketCountChange={handleChangeTicketCount}
                                onAddToCart={addTicketsToCart}
                                disabled={preview || !hasSelectedTicket(ticket.id)}
                                disabledCount={isDisabledTicketsCount(ticket)}
                                isLoading={isLoading}
                                ticketCount={
                                    ticketsCount.find((ticketItem) => ticketItem.ticket.id === ticket.id)
                                        ?.count || 0
                                }
                                price={ticketsStatic.getPriceInfo(ticket)}
                                acronym={event.acronym}
                            />
                            <Divider className={classes.divider} />
                        </React.Fragment>
                    ))}
                </Grid>
                <Grid item className={classes.tickets} xs={12}>
                    <BuyNowButton
                        disabled={preview || !hasSelectedTicket!() || isLoading}
                        onAddTickets={addTicketsToCart}
                        acronym={event.acronym}
                        embeded={Number(embeded)}
                    />
                </Grid>
            </Grid>
        </TopBorderContainer>
    );
};

enum EMBEDED {
    FALSE = 0,
    TRUE = 1,
}
type BuyNowButtonProps = {
    disabled: boolean;
    onAddTickets: () => void;
    acronym: string;
    embeded?: EMBEDED;
};
const BuyNowButton = ({ disabled, onAddTickets, acronym, embeded }: BuyNowButtonProps) => {
    const classes = useStyles();
    const getLiteral = useLiterals();

    const buttonProps: { [key: number]: any } = {
        [EMBEDED.FALSE]: {
            color: "primary",
            variant: "contained",
            fullWidth: true,
            size: "large",
            disabled: disabled,
            onClick: () => onAddTickets(),
        },
        [EMBEDED.TRUE]: {
            color: "primary",
            variant: "contained",
            fullWidth: true,
            size: "large",
            disabled: disabled,
            target: "_blank",
            href: `${events.url}/events/${acronym}`,
        },
    };

    return (
        <Button {...buttonProps[embeded || EMBEDED.FALSE]}>
            <AddShoppingCartIcon className={classes.buyNowIcon} />
            {getLiteral("event_details.ticket.button.buy_now")}
        </Button>
    );
};

export { Tickets };
