import React from "react";

import { DropDownListField, EventChange } from "@djordjeandjelkovic/medgress_common_react_modules";
import { IOption } from "$models/Option";

import { useStyles } from "./styles";
import { useLiterals } from "$hooks/Translate/useLiterals";

interface TicketCountProps {
    ticketId: string;
    count: number | undefined;
    name: string;
    disabled?: boolean;
    onChange: (event: EventChange<string>, ticketId: string) => void;
}

export const TicketCount = (props: TicketCountProps) => {
    const classes = useStyles();
    const getLiteral = useLiterals();

    const countOptions = createOptions((key) => getLiteral(key));

    return (
        <DropDownListField
            onChange={(e: EventChange<string>) => props.onChange(e, props.ticketId)}
            fullWidth={true}
            disabled={props.disabled || false}
            value={props.count === undefined ? "" : props.count.toString()}
            label={getLiteral("event_details.ticket_count.label")}
            FormControlProps={{ className: classes.ticketsCount }}
            SelectProps={{ className: classes.ticketSelect }}
            name={props.name}
            options={countOptions}
        />
    );
};

function createOptions(literal: (key: string) => string) {
    const options = [] as IOption[];
    for (let i = 0; i <= 10; i++) {
        options.push({ name: literal(`event_details.ticket_count.options.${i}`), value: `${i}` });
    }

    return options;
}
