import React from "react";
import { Typography } from "@material-ui/core";
import { DisplayTicketLimitEnum } from "@djordjeandjelkovic/medgress_common_react_modules";

type AvailableTicketsProps = {
    total?: number;
    sold: number;
    showAvailableTickets: number;
    disabledCount: boolean;
};

export const AvailableTickets = (props: AvailableTicketsProps) => {
    const { total, sold, disabledCount, showAvailableTickets } = props;

    if (showAvailableTickets === DisplayTicketLimitEnum.DONT_SHOW || total === null || total === undefined) {
        return null;
    }

    const totalLeft = total - sold < 0 ? 0 : total - sold;
    return <Typography>{disabledCount ? "--" : `${totalLeft} ticket(s) left.`}</Typography>;
};
