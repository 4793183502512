import React, { useEffect, useState } from "react";

import _reduce from "lodash/reduce";
import _map from "lodash/map";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import PersonIcon from "@material-ui/icons/Person";

import { ExpandButton } from "$components/core";

import CollapseAttendee from "./CollapseAttendee";
import { Expand, TicketCartAttendeesProps } from "./Models";
import { formatTitle } from "./ticket-utils";

import ticketCartService from "$services/ticketcart.service";
import { useMessage } from "$helper/useMessage";
import ViewTicketPDF from "./ViewTicketPDF/ViewTicketPDF";

import { useStyles } from "./styles";
import { PaymentStatusEnum } from "@djordjeandjelkovic/medgress_common_react_modules";

export default (props: TicketCartAttendeesProps) => {
    const { attendees, cartId, purchaseHistoryDetails } = props;
    const classes = useStyles();
    const { showError, showSuccess } = useMessage();

    const [expand, setExpand] = useState<Expand>({});
    const [viewTicket, setViewTicket] = useState(false);
    const [isLoadingResend, setLoadingResend] = useState(false);

    useEffect(() => {
        setExpand(
            _reduce(
                attendees,
                (map, hAttendee) => {
                    map[hAttendee.attendee.id.value] = true;
                    return map;
                },
                {} as Expand
            )
        );
    }, [attendees]);

    const handleExpand = (id: string) => {
        setExpand((prev) => ({ ...prev, [id]: !prev![id] }));
    };

    const handleViewTicket = () => {
        setViewTicket((prev) => !prev);
    };

    const handleResendTicket = async (attendeeId: string) => {
        setLoadingResend(true);
        try {
            await ticketCartService.resendTicket(cartId, attendeeId);
            showSuccess("Ticket has been resent.");
        } catch (error) {
            showError("Error while resending ticket. Try again later.");
            console.error(error);
        } finally {
            setLoadingResend(false);
        }
    };

    console.log(JSON.stringify(purchaseHistoryDetails[0]?.paymentStatus));

    return (
        <>
            {_map(attendees, (hAttendee) => (
                <React.Fragment key={hAttendee.attendee.id.value}>
                    <Card className={classes.card} key={hAttendee.attendee.id.value}>
                        <CardHeader
                            titleTypographyProps={{ variant: "h6" }}
                            avatar={<PersonIcon />}
                            title={formatTitle(hAttendee)}
                            action={
                                <HeaderActions
                                    expand={{
                                        attendeeId: hAttendee.attendee.id.value,
                                        onExpand: handleExpand,
                                        expand: expand[hAttendee.attendee.id.value],
                                    }}
                                />
                            }>
                        </CardHeader>
                        
                        <CollapseAttendee
                            in={expand[hAttendee.attendee.id.value]}
                            attendee={hAttendee.attendee}
                            resend={{
                                isLoading: isLoadingResend,
                                attendeeId: hAttendee.attendee.id.value,
                                onResend: handleResendTicket,
                            }}
                            view={{ onView: handleViewTicket }}
                            download={{
                                eventId: hAttendee.attendee.eventId!.value,
                                attendeeId: hAttendee.attendee.id.value,
                            }}
                            showTicketActions={purchaseHistoryDetails[0].paymentStatus === PaymentStatusEnum.SUCCESS}
                        />
                    </Card>
                    <ViewTicketPDF
                        show={viewTicket}
                        onClose={handleViewTicket}
                        eventId={hAttendee.attendee.eventId!.value}
                        attendeeId={hAttendee.attendee.id.value}
                    />
                </React.Fragment>
            ))}
        </>
    );
};

type HeaderActionsType = {
    expand: {
        attendeeId: string;
        onExpand: (id: string) => void;
        expand: boolean;
    };
};

const HeaderActions = (props: HeaderActionsType) => {
    const { expand } = props;
    return (
        <ExpandButton id={expand.attendeeId} onClick={expand.onExpand} expand={expand.expand} />
    );
};
